import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import IconBackrrow from "../SvgIcons/IconBackrrow";
import "./style.css";
import IconTallyPrimelogo from "../SvgIcons/IconTallyPrimelogo";
import IconRightArrow from "../SvgIcons/IconRightArrow";
import GlobalLogo from "../SvgIcons/GlobalLogo";
import IconBurger from "../SvgIcons/IconBurger";
import IconClose from "../SvgIcons/IconClose";

function NavigationPhone({ isNavOpen, setIsNavOpen }) {
  const [openMenu, setOpenMenu] = useState(null);
  const [initiallyOpenedMenu, setInitiallyOpenedMenu] = useState(null);
  const [activeTab, setActiveTab] = useState(1);
  const [activeInnerMenu, setActiveInnerMenu] = useState(null);

  useEffect(() => {
    setInitiallyOpenedMenu(true);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      // Close the menu if it is open
      if (isNavOpen) {
        setIsNavOpen(false);
        setOpenMenu(null);
        setActiveInnerMenu(null);
      }
    };
    // Add event listener for scroll events
    window.addEventListener("scroll", handleScroll);
    // Remove event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isNavOpen, setIsNavOpen]);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
    setOpenMenu(null);
    setActiveInnerMenu(null);
  };

  const toggleInnerMenu = (menu) => {
    if (openMenu === menu) {
      setActiveInnerMenu(null);
      setOpenMenu(null); // Close the dropdown if clicking on the same menu again
    } else {
      setActiveInnerMenu(menu);
      setOpenMenu(menu); // Open the dropdown for the clicked menu
    }
    // Close the initially opened menu if it's open and a different menu is clicked
    if (menu !== initiallyOpenedMenu && openMenu === initiallyOpenedMenu) {
      setOpenMenu(null);
    }   
  };

  const openTab = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  
  return (
    <div className="headertwo">
      <div div className="sidebarmenu">
        <input
          type="checkbox"
          id="navcheck"
          role="button"
          title="menu"
          checked={isNavOpen}
          onChange={toggleNav}
        />
        <label htmlFor="navcheck" aria-hidden="true" title="menu">
          <span className="burger">
            {isNavOpen ? (
              <>
                <IconClose /> <strong className="menutext">Close</strong>
              </>
            ) : (
              <>
                <IconBurger /> <strong className="menutext">Menu</strong>
              </>
            )}
          </span>
        </label>
        <nav
          id="menu"
          className={isNavOpen ? "open" : ""}
          data-active-inner-menu={activeInnerMenu}
        >
          <ul className={openMenu ? "innerhidemenu" : ""}>
            <li>
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0 });
                }}
                to="/"
              >
                Home
              </Link>
            </li>
            <li>
              <Link onClick={() => toggleInnerMenu("products")}>Products</Link>
            </li>
            <li>
              <Link onClick={() => toggleInnerMenu("services")}>Services</Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0 });
                }}
                to="/price-india"
              >
                Pricing
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0 });
                }}
                to="/events"
              >
                Events
              </Link>
            </li>
            <li>
              <Link onClick={() => toggleInnerMenu("downloads")}>
                Downloads
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0 });
                }}
                to="/career"
              >
                Career
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0 });
                }}
                to="/about"
              >
                About
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0 });
                }}
                to="/contact"
              >
                Contact
              </Link>
            </li>
          <li>
              <Link to="https://globalinfosoft.net/crf/" target="_blank">
                My Account
              </Link>
            </li>
          </ul>

          {/* Products menu */}
          <div
            className={
              openMenu === "products" ? "menudown opendown" : "menudown"
            }
          >
            <Link
              onClick={() => toggleInnerMenu("products")}
              className="menu-link"
            >
              <IconBackrrow /> Tally Products
            </Link>

            <div className="menutab-container">
              <Link
                className={`menutab ${activeTab === 1 ? "active" : ""}`}
                onClick={() => openTab(1)}
              >
                TallyPrime
              </Link>
              <Link
                className={`menutab ${activeTab === 2 ? "active" : ""}`}
                onClick={() => openTab(2)}
              >
                TallyPrime Server
              </Link>
            </div>

            <div className="menuinnertabs">
              <div
                className={`menutab-content ${activeTab === 1 ? "active" : ""}`}
              >
                <div className="readmore">
                  <IconTallyPrimelogo />
                </div>
                <Link
                  onClick={() => {
                    window.scrollTo({ top: 0 });
                  }}
                  to="/tally-prime"
                  className="btn btn-purpal"
                >
                  <label>
                    Read More <IconRightArrow />
                  </label>
                  <span></span>
                </Link>
              </div>
              <div
                className={`menutab-content ${activeTab === 2 ? "active" : ""}`}
              >
                <div className="readmore">
                  <img
                    src="images/TallyPrimeServer/tallyprime-serverlogo.svg"
                    className="img-fluid"
                    alt="Tally Prime Server"
                  />
                </div>
                <Link
                  onClick={() => {
                    window.scrollTo({ top: 0 });
                  }}
                  to="/tally-prime-server"
                  className="btn btn-purpal"
                >
                  <label>
                    Read More <IconRightArrow />
                  </label>
                  <span></span>
                </Link>
              </div>
            </div>

            <div className="innerlink phonemenuinner">
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0 });
                }}
                to="/tally-prime"
              >
                Tally Products
              </Link>
            </div>
            <div className="innerlink">
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0 });
                }}
                to="/our-products"
              >
                Our Solutions & Products
              </Link>
            </div>
          </div>
          {/* Products menu end*/}

          {/* Services menu*/}
          <div
            className={
              openMenu === "services" ? "menudown opendown" : "menudown"
            }
          >
            <Link
              onClick={() => toggleInnerMenu("services")}
              className="menu-link"
            >
              <IconBackrrow /> Services
            </Link>

            <div className="menutab-container">
              <Link
                className={`menutab ${activeTab === 1 ? "active" : ""}`}
                onClick={() => openTab(1)}
              >
                Tally Support
              </Link>
              <Link
                className={`menutab ${activeTab === 2 ? "active" : ""}`}
                onClick={() => openTab(2)}
              >
                Corporate Implementation
              </Link>
              <Link
                className={`menutab ${activeTab === 3 ? "active" : ""}`}
                onClick={() => openTab(3)}
              >
                Value Added Services
              </Link>
              <Link
                className={`menutab ${activeTab === 4 ? "active" : ""}`}
                onClick={() => openTab(4)}
              >
                Annual Maintenance Contract
              </Link>
            </div>
            <div className="menuinnertabs">
              <div
                className={`menutab-content servicetabs ${
                  activeTab === 1 ? "active" : ""
                }`}
              >
                <div className="servicesreadmore">
                  <Link
                    onClick={() => {
                      window.scrollTo({ top: 0 });
                    }}
                    to="/tally-support"
                  >
                    <img
                      src="images/ServicesSupport/support1.png"
                      className="img-fluid"
                      alt="Tally Support"
                    />
                  </Link>
                </div>
                <Link
                  onClick={() => {
                    window.scrollTo({ top: 0 });
                  }}
                  to="/tally-support"
                  className="btn btn-purpal"
                >
                  <label>
                    Read More <IconRightArrow />
                  </label>
                  <span></span>
                </Link>
              </div>
              <div
                className={`menutab-content servicetabs ${
                  activeTab === 2 ? "active" : ""
                }`}
              >
                <div className="servicesreadmore">
                  <Link
                    onClick={() => {
                      window.scrollTo({ top: 0 });
                    }}
                    to="/corporate-implementation"
                  >
                    <img
                      src="images/ServicesSupport/support2.png"
                      className="img-fluid"
                      alt="corporate-implementation"
                    />
                  </Link>
                </div>
                <Link
                  onClick={() => {
                    window.scrollTo({ top: 0 });
                  }}
                  to="/corporate-implementation"
                  className="btn btn-purpal"
                >
                  <label>
                    Read More <IconRightArrow />
                  </label>
                  <span></span>
                </Link>
              </div>
              <div
                className={`menutab-content servicetabs ${
                  activeTab === 3 ? "active" : ""
                }`}
              >
                <div className="servicesreadmore">
                  <Link
                    onClick={() => {
                      window.scrollTo({ top: 0 });
                    }}
                    to="/value-added-services"
                  >
                    <img
                      src="images/ServicesSupport/support3.png"
                      className="img-fluid"
                      alt="value-added-services"
                    />
                  </Link>
                </div>
                <Link
                  onClick={() => {
                    window.scrollTo({ top: 0 });
                  }}
                  to="/value-added-services"
                  className="btn btn-purpal"
                >
                  <label>
                    Read More <IconRightArrow />
                  </label>
                  <span></span>
                </Link>
              </div>
              <div
                className={`menutab-content servicetabs ${
                  activeTab === 4 ? "active" : ""
                }`}
              >
                <div className="servicesreadmore">
                  <Link
                    onClick={() => {
                      window.scrollTo({ top: 0 });
                    }}
                    to="/annual-maintainance-contract"
                  >
                    <img
                      src="images/ServicesSupport/support4.png"
                      className="img-fluid"
                      alt="annual-maintainance-contract"
                    />
                  </Link>
                </div>
                <Link
                  onClick={() => {
                    window.scrollTo({ top: 0 });
                  }}
                  to="/annual-maintainance-contract"
                  className="btn btn-purpal"
                >
                  <label>
                    Read More <IconRightArrow />
                  </label>
                  <span></span>
                </Link>
              </div>
            </div>
            <div className="innerlink phonemenuinner">
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0 });
                }}
                to="/tally-support"
              >
                Tally Support
              </Link>
            </div>
            <div className="innerlink phonemenuinner">
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0 });
                }}
                to="/corporate-implementation"
              >
                Corporate Implementation
              </Link>
            </div>
            <div className="innerlink phonemenuinner">
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0 });
                }}
                to="/value-added-services"
              >
                Value Added Services
              </Link>
            </div>
            <div className="innerlink phonemenuinner">
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0 });
                }}
                to="/annual-maintainance-contract"
              >
                Annual Maintenance Contract
              </Link>
            </div>
          </div>
          {/* Services menu  end*/}

          {/* Downloads menu  */}
          <div
            className={
              openMenu === "downloads" ? "menudown opendown" : "menudown"
            }
          >
            <Link
              onClick={() => toggleInnerMenu("downloads")}
              className="menu-link"
            >
              <IconBackrrow /> Downloads
            </Link>
            <div className="innerlink">
              <a href="images/satup/tallyprime_setup5-0.exe" download>
                Tallyprime
              </a>
            </div>
            <div className="innerlink">
              <a href="images/satup/TallyPrimeServer_setup4-0.exe" download>
                TallyPrime Server
              </a>
            </div>
            <div className="innerlink">
               <a href="images/satup/AnyDesk.exe" download>
                AnyDesk
              </a>
            </div>
            <div className="innerlink">
            <a href="images/satup/UltraViewer_Desktop.exe" download>
                 Ultra Viewer
                </a>
            </div>
           

            <div className="innerlink">
             <Link to="https://play.google.com/store/apps/details?id=globalsoftwares.com.global_query&pli=1" target="_blank"> 
                Global Tally Care App
              </Link>
            </div>
          </div>
          {/* Downloads menu  end*/}
        </nav>
      </div>

      <div className="logo">
        <Link
          to="/"
          onClick={() => {
            window.scrollTo({ top: 0 });
          }}
        >
          <GlobalLogo />
        </Link>
      </div>
      <a href="tel:+91 7966617000" className="phoneicon">
        <span>Call Us</span>
        <img src="images/telephone.png" alt="phone" />
      </a>
    </div>
  );
}

export default NavigationPhone;

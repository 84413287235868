import React from 'react'
import { Link } from 'react-router-dom'
import './style.css'


export default function TellyprimeSection() {
  return (
    <section className='section'>
        <div className='container'>
   
      {/* <div class="center-box"> 
      <div class="animated-border-box-glow"></div>
      <Link to="/tallyprime-now" class="animated-border-box">
      TallyPrime 5.0 is Now Available 
      </Link>  
    </div> */}

      <div class="blockAvailable">       
      <Link to="/tallyprime-now">
      TallyPrime 5.0 is Now Available 
      </Link>  
    </div>

   </div>
   </section>
  )
}

import React, { useState } from "react";
import PageLayout from "../../component/PageLayout";
import GoogleTagManager from "../../component/GoogleTagManager";
import './style.css';
import IconRightArrow from "../../component/SvgIcons/IconRightArrow";
import { Link, useNavigate } from "react-router-dom";
import Axios from 'axios'; // make sure Axios is imported

export default function TellyprimeNow() {
  const [eventDetails, setEventDetails] = useState(null);
  const [eventDate, setEventDate] = useState(""); // State for eventDate
  const navigate = useNavigate();

  const handleRegisterNow = () => {    
    navigate("/event-details", { state: { eventDetails, eventDate } });
    window.scrollTo({ top: 0 });
  };

  const fetchEvents = async (eventDate) => {
    try {
      const response = await Axios.post("https://gshost.in/GS/EventAPI/event.php", {
        GSEvent: {
          event_date: eventDate,
        },
      });
      const eventData = response.data.events || []; 
      setEventDate(eventDate); 
      setEventDetails(eventData);
    } catch (error) {
      console.error("Error fetching events:", error);            
    }
  };

  return (
    <div className="TallyPrimebg newreleaseTally">
      <GoogleTagManager />
      <PageLayout>

      <div className="filve-release-main">
       <div className="filve-Bg-right"></div>
      <div className="container">
      <div className="filve-release-inner">
        <div className="filve-release-left">
        <div className="filve-easyasimg">
            <img src="../images/banner-easyasimg.svg" className="img-fluid" alt="TallyPrime 5.0 is Available"/> 
            </div>
            <div className="tally-prime-five">
            <img src="../images/tally-prime-5-0-logo.svg" className="img-fluid" alt="TallyPrime 5.0 is Available"/>             
            </div>
            <ul>
                <li>Lightning fast GST uploads</li>
                <li>One click GST reconciliation</li>
                <li>100% control on ITC risks</li>
                <li>Automated ledger creation</li>
            </ul>
            </div>
            <div className="filve-release">
            <img src="../images/home-tallyprimerel5.svg" className="img-fluid" alt="TallyPrime 5.0 is Available"/> 
            </div>
        </div>
        <div
          className="button d-flex gap50">
          <a href="images/satup/tallyprime_setup5-0.exe" download className="btn btn-danger">
            <label>Download Now <IconRightArrow /></label><span></span>
          </a>
          <Link to="/contact" onClick={() => { window.scrollTo({ top: 0 });}} className="btn btn-dark">
            <label>Contact Us Now <IconRightArrow /></label><span></span>
          </Link>

          {/* <Link to="/event-details" onClick={() => { window.scrollTo({ top: 0 });}} className="btn btn-danger">
            <label> Register for Webinar <IconRightArrow /></label><span></span>
          </Link>   */}
          <button
          onClick={handleRegisterNow}
          className="btn btn-danger"
        >
            <label>
            Register for Webinar<IconRightArrow />
            </label>
            <span></span>
          </button>

        </div>

       
        </div>
        </div>

        <div className="container">
            
      <div className="announce-title">
      <div className="titletwo"><h2>We're excited to announce the gold launch of <span>TallyPrime Release 5.0.</span></h2>
      <h3>It’s now available for download. If you have a valid TSS, you can start using it right away.</h3>
        <p><strong>TallyPrime Release 5.0</strong> comes with the following new features:</p>
      </div>

      </div>


        <section className="peragraf-content">
       
        <div className="listcontent listcontent1">
        <div className="experience-pic">
         <img src="../images/tellyprime-now/1.svg" alt="Connected GST Experience"/> 
          </div>  
        <div className="experience-info">
        <h5>Connected GST Experience</h5>
        <p>Transform your GST upload and filing experience with powerful integration and automation capabilities. Upload GSTR-1 and GSTR-3B data, file GSTR-1, and auto-reconcile GSTR-2A/2B – all without visiting the GST portal. Built-in error detection and correction ensure precise filings, giving you the confidence and accuracy.
        </p>
        </div>  
        </div>

        <div className="listcontent listcontent2">
        <div className="experience-pic">
         <img src="../images/tellyprime-now/5.svg" alt="One Click GST Reconciliation"/> 
          </div> 
          <div className="experience-info">
        <h5>One Click GST Reconciliation</h5>
        <p>With a single click, TallyPrime automatically downloads and reconciles GSTR-2A/2B, GSTR-1, and GSTR-3B. Get comprehensive summaries of reconciled transactions and mismatches. Quickly reconcile multiple returns, GSTINs, and periods to save time and boost efficiency.</p>
        </div> 
        </div>

        <div className="listcontent listcontent3">
        <div className="experience-pic">
         <img src="../images/tellyprime-now/2.svg" alt="100% Control on ITC Risks"/> 
          </div> 
        <div className="experience-info">
        <h5>100% Control on ITC Risks</h5>
        <p>Safeguard ITC with real-time risk monitoring and proactive management tools. View ITC at risk in the payable report, get precomputed payment suggestions, and keep your ITC status up to date with dynamic reconciliation insights. Reduce potential risks to tax credits and manage your working capital more effectively.</p>
        </div>
        </div>
        <div className="listcontent listcontent4">
        <div className="experience-pic">
         <img src="../images/tellyprime-now/3.svg" alt="Automated Ledger Creation"/> 
          </div> 
          <div className="experience-info">
        <h5>Automated Ledger Creation</h5>
        <p>Easily create a ledger by fetching party details from the GST portal using just the GSTIN. GSTIN details are retrieved from the portal and populated into the ledger data, saving time and reducing errors. You can create single or bulk ledgers with ease. Additionally, check the party’s GST filing status to assess their compliance health and take appropriate actions.</p>
        </div>
        </div>

        <div className="listcontent listcontent5">
        <div className="experience-pic">
         <img src="../images/tellyprime-now/4.svg" alt="Simplified TDS 194Q Compliance"/> 
        </div> 
        <div className="experience-info">
        <h5>Simplified TDS 194Q Compliance</h5>
        <p>For businesses with a turnover exceeding INR 10 crore from the previous financial year and payments exceeding 50 lakh for goods in the current year, the latest release helps you efficiently manage TDS deductions for ‘Purchase of Goods’ and ensure regulatory compliance.</p>
        </div>
        </div>
{/* 
        <div className="listcontent">
        <h5>Other enhancements and new capabilities:</h5>
            <ul>
                <li><span>– Stripe View: Enhanced Report Readability</span> <p>	
                Enjoy the enhanced UI for easy data viewing and readability. Enable it for exporting, printing, and sharing invoices and reports.</p></li>
                <li><span>– Notification Frame: Never Miss a Task</span> <p>	
                Get timely alerts for critical tasks like GST returns, TSS renewals, license management, and product upgrades.</p></li>
                <li><span>– Bill Sorting: Prioritize Payments</span> <p>	                	
                Automatically sort and settle bills by the due date, ensuring older bills are paid first to avoid late fees and maintain strong supplier relationships.</p></li>
            </ul>
        </div> */}


        <div className="listcontent" style={{textAlign:"center"}}>
        <p>The latest release is available for TallyPrime, TallyPrime Edit Log, TallyPrime Server, TallyPrime Developer, and TallyPrime powered by AWS.</p>        
        </div>


        </section>

        </div>

      </PageLayout>
    </div>
  );
}
